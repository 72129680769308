@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "bold";
  src: local("verdana"),
    url("./fonts/verdana/verdana-bold.ttf") format("truetype");
  font-weight: bold;
}
@font-face {
  font-family: "regular";
  src: local("verdana"), url("./fonts/verdana/verdana.ttf") format("truetype");
}

.bold {
  font-family: "bold";
}
body {
  font-family: "regular";
}
